<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px" style="display: flex;padding-bottom: 0">
        <ayl-search-box :query="table.query" :search-data='searchData' @on-search-change='onSearchChange'>
          <div slot="after" style="width: 100%;">
            <div class="export-btn ml10px" @click="clickExport">查询数据导出</div>
            <el-button @click="$router.go(-1)" style="float: right">返 回</el-button>
          </div>
        </ayl-search-box>
      </div>
      <div class="content-main">
        <div class="mb24px">
          <span>驾驶员: {{$route.query.driverName}}</span>
          <span class="ml24px">查询日期:
            {{startTime|str2ymd}}
            -
            {{endTime|str2ymd}}</span>
        </div>
        <ayl-table :table="table" style="width: 100%"></ayl-table>
      </div>
      <!--导出-->
      <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="900px" center>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
          <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
        </el-checkbox-group>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="exportExcel" class="mr24px" :loading="btnLoading">确 定</el-button>
          <el-button @click="dialogExport=false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import mixins from '@utils/mixins'

  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [
          {name: '会计对账'},
          {name: '外请车主统计', path: 'go(-1)'},
          {name: '承运明细'},
        ],
        searchData: [{
          type: 'select',
          title: '订单状态',
          model: 'orderStatus',
          placeholder: '请选择',
          option: [{
            label: '已核算',
            value: '已核算'
          }, {
            label: '已对账',
            value: '已对账'
          }, {
            label: '已结算',
            value: '已结算'
          }]
        }],
        table: {
          api: vm.$api.ListOrderAtAccountChecking,
          query: {
            type: 2,
            orderStatus: '',
            startTime: vm.$route.query.startTime || null,
            endTime: vm.$route.query.endTime || null,
            queryId: vm.$route.query.driverId,
          },
          // 表格查询的条件组件
          columns: [{
            title: '运输日期',
            key: 'transportTime',
            filter: 'str2ymd',
          }, {
            title: '提货车号',
            key: 'plateNumber',
          }, {
            title: '客户名称',
            key: 'partnerName',
            width: '100px',
            showTooltip: true,
          }, {
            title: '结算客户',
            key: 'partnerAccountName',
            width: '100px',
            showTooltip: true,
          }, {
            title: '起运地',
            key: 'sendAddressDetail',
            width: '100px',
            showTooltip: true,
          }, {
            title: '目的地',
            key: 'receiveAddressDetail',
            width: '100px',
            showTooltip: true,
          }, {
            title: '订单状态',
            key: 'orderStatus',
          }, {
            title: '成本运费',
            key: 'costCarrierFee',
          }, {
            title: '吊装费',
            key: 'liftingFee',
          }, {
            title: '卸车费',
            key: 'unloadFee',
          }, {
            title: '过磅费',
            key: 'weightFee',
          }, {
            title: '信息费',
            key: 'messageFee',
          }, {
            title: '操作',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goAccounting.bind(this, ctx.row)
                  }
                }, '订单详情'),
              ])
            }
          }]
        },
        dialogExport: false,
        startTime: null,
        endTime: null,
        btnLoading: false,
        checkIndex: [],
        checkList: [],
      }
    },
    methods: {
      async exportExcel () {
        this.btnLoading = true
        try {
          const url = await this.$api.Excel({
            excelKey: 'SteelInternalDriverAccount',
            indexList: this.checkIndex,
            params: {
              type: 2,
              orderStatus: this.table.query.orderStatus,
              startTime: this.table.query.startTime || null,
              endTime: this.table.query.endTime || null,
              queryId: this.table.query.queryId,
              orderIds: []
            }
          })
          window.open(url)
          this.dialogExport = false
          await this.$search(this.table)
        } catch (e) {
          console.log(e)
        }
        this.btnLoading = false
      },
      clickExport () {
        // 导出
        this.$api.GetExcelExportKeyAndColumn({
          excelKey: 'SteelInternalDriverAccount'
        }).then((res) => {
          this.checkList = res.chsList
          this.checkIndex = this.checkList.map(e => {
            return e.index
          })
        })
        this.dialogExport = true
      },
      goAccounting (e) {
        this.$router.push({
          path: '/accounting/external-accounting',
          query: {
            orderId: e.orderId,
            page: '外部驾驶员'
          }
        })
      },
      onSearchChange (e) {
        this.$search(this.table, e)
        if (!this.table.hidePagination) {
          this.$set(this.table.pagination, 'currentPage', 1)
        }
      }
    },
    // beforeRouteLeave (to, from, next) {
    //   to.meta.keepAlive = true
    //   next()
    // },
    async mounted () {
      this.table.query.startTime = Number(this.$route.query.startTime) || null
      this.table.query.endTime = Number(this.$route.query.endTime) || null
      this.table.query.queryId = this.$route.query.driverId
      // await this.$search(this.table)
      this.startTime = this.table.query.startTime || this.table.other.minTranSportTime
      this.endTime = this.table.query.endTime || this.table.other.maxTranSportTime
    },
    async activated () {

    }
  }
</script>

<style lang='sass' scoped>
  .content-box
    .search-box
      flex: 1

    .details-form-item
      width: 50% !important

    /deep/ .el-form-item
      width: 48% !important

    /deep/ .el-checkbox
      min-width: 92px
</style>
